import {
  FieldInputTypes,
  type INodeField,
  type INodeFieldValue,
} from '@manager'

export const hasFieldValues = (
  node: INodeField,
): node is Extract<INodeField, { values: INodeFieldValue[] }> => {
  return (
    node.type === FieldInputTypes.CHECKBOX ||
    node.type === FieldInputTypes.OPTIONS ||
    node.type === FieldInputTypes.DROPDOWN
  )
}
